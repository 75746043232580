import React from "react";
import { Formular } from "../Formular";
import styled from "styled-components";
import { mobile, tablet } from "../../responsive";

export const Contact = () => {
  return (
    <Container>
      <Formular />
    </Container>
  );
};

const Container = styled.div`
  height: 65vh;
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25rem;
  ${mobile({
    height: "80vh",
    width: "100%",
    marginLeft: "0",
  })}
  ${tablet({
    height: "80vh",
    width: "100%",
    marginLeft: "0",
  })}
`;
