import React from "react";
import styled from "styled-components";
import hero from "../../Assets/Picture/HERO.png";
import Tak1 from "../../Assets/Picture/Tak1.png";
import Tak2 from "../../Assets/Picture/Tak2.png";
import Tak3 from "../../Assets/Picture/Tak3.jpg";
import Tak4 from "../../Assets/Picture/Tak4.jpg";
import { mobile } from "../../responsive";
import ButtonStyle from "../ButtonStyle";

export const Tak = () => {
  return (
    <Container>
      <Company>
        <h3> INFORMATION OM TAK/BOLAGET</h3>
        <CompanyDesc>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Platea mattis
            euismod id etiam nullam ad. Fames turpis orci; lobortis fermentum
            fames magnis arcu. Vivamus maecenas vitae tempor tincidunt primis
            sed dui maximus. Elementum himenaeos augue senectus tempus ante eget
            enim eget. Donec ullamcorper euismod odio a amet placerat justo.
            Sagittis convallis malesuada laoreet duis varius bibendum auctor.
          </p>
        </CompanyDesc>
      </Company>
      <img src={hero} alt="" width="100%" />
      <ContactContainer>
        {" "}
        <h1>KONTAKA OSS</h1>
        <h2>Behöver du hjälp med något?</h2>
      </ContactContainer>
      <ButtonStyle />
      <RefPictures>
        {" "}
        <img src={Tak1} alt="" />
        <img src={Tak2} alt="" />
        <img src={Tak3} alt="" />
        <img src={Tak4} alt="" />
      </RefPictures>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Company = styled.div`
  margin-top: 2rem;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({
    fontSize: "16px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "-0.5rem",
    marginBottom: "0.5rem",
    maxWidth: "100%",
  })}
`;

const CompanyDesc = styled.p`
  margin-bottom: 1rem;
  ${mobile({
    fontSize: "14px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "0.5rem",
    lineHeight: "21px",
    maxWidth: "100%",
  })}
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({
    fontSize: "10px",
    width: "400px",
    paddingRight: "2rem",
    paddingLeft: "2rem",
    marginTop: "0.5rem",
    lineHeight: "12px",
    maxWidth: "100%",
  })}
`;

const RefPictures = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  margin-bottom: 3rem;

  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  ${mobile({
    display: "grid",
    gridTemplateColumns: "1fr;",
    width: "100%",
    marginBottom: "1rem",
  })}
`;
