import React from "react";
import styled from "styled-components";
import Kok1 from "../../Assets/Picture/kok1.jpg";
import Kok2 from "../../Assets/Picture/kok2.jpg";
import Kok3 from "../../Assets/Picture/kok3.jpg";
import Kok4 from "../../Assets/Picture/kok4.jpg";
import { mobile } from "../../responsive";
import ButtonStyle from "../ButtonStyle";

const Kok = () => {
  return (
    <Container>
      <Company>
        <h3>INFORMATION OM TAK/BOLAGET</h3>
        <CompanyDesc>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Platea mattis
            euismod id etiam nullam ad. Fames turpis orci; lobortis fermentum
            fames magnis arcu. Vivamus maecenas vitae tempor tincidunt primis
            sed dui maximus. Elementum himenaeos augue senectus tempus ante eget
            enim eget. Donec ullamcorper euismod odio a amet placerat justo.
            Sagittis convallis malesuada laoreet duis varius bibendum auctor.
          </p>
        </CompanyDesc>
      </Company>

      <ContactContainer>
        {" "}
        <h1>KONTAKA OSS</h1>
        <h2>Behöver du hjälp med något?</h2>
      </ContactContainer>
      <ButtonStyle />
      <RefPictures>
        {" "}
        <img src={Kok1} alt="" />
        <img src={Kok2} alt="" />
        <img src={Kok3} alt="" />
        <img src={Kok4} alt="" />
      </RefPictures>
    </Container>
  );
};

export default Kok;

const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Company = styled.div`
  margin-top: 2rem;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({
    fontSize: "16px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "-0.5rem",
    marginBottom: "0.5rem",
    maxWidth: "100%",
  })}
`;

const CompanyDesc = styled.div`
  ${mobile({
    fontSize: "14px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "0.5rem",
    lineHeight: "21px",
    maxWidth: "100%",
  })}
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({
    fontSize: "10px",
    width: "400px",
    paddingRight: "2rem",
    paddingLeft: "2rem",
    marginTop: "0.5rem",
    lineHeight: "12px",
    maxWidth: "100%",
  })}
`;

const RefPictures = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  margin-bottom: 3rem;

  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
`;
