import React from "react";
import ruta1 from "../../Assets/Picture/KOK.png";
import ruta2 from "../../Assets/Picture/BADRUM.png";
import ruta3 from "../../Assets/Picture/ALTAN.png";
import ruta4 from "../../Assets/Picture/OVRIGT.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { mobile } from "../../responsive";

export const Tjanster = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Company>
        <h3>INFORMATION OM TJÄNSTERNA</h3>
        <CompanyDesc>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Platea mattis
            euismod id etiam nullam ad. Fames turpis orci; lobortis fermentum
            fames magnis arcu. Vivamus maecenas vitae tempor tincidunt primis
            sed dui maximus. Elementum himenaeos augue senectus tempus ante eget
            enim eget. Donec ullamcorper euismod odio a amet placerat justo.
            Sagittis convallis malesuada laoreet duis varius bibendum auctor.
          </p>
        </CompanyDesc>
      </Company>
      <RefPictures>
        <img
          src={ruta1}
          alt=""
          width="100%"
          onClick={() => navigate("/Kok")}
        ></img>
        <img
          src={ruta2}
          alt=""
          width="100%"
          onClick={() => navigate("/Badrum")}
        ></img>
        <img
          src={ruta3}
          alt=""
          width="100%"
          onClick={() => navigate("/Altan")}
        ></img>
        <img
          src={ruta4}
          alt=""
          width="100%"
          onClick={() => navigate("/Ovrigt")}
        ></img>
      </RefPictures>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20rem;
  ${mobile({
    marginBottom: "2rem",
  })}
`;

const Company = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({
    fontSize: "16px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "-0.5rem",
    marginBottom: "0.5rem",
    maxWidth: "100%",
  })}
`;
const CompanyDesc = styled.div`
  margin-top: 2rem;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({
    fontSize: "14px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "0.5rem",
    lineHeight: "21px",
    maxWidth: "100%",
  })}
`;

const RefPictures = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1rem;
  margin-top: 3rem;
  ${mobile({
    display: "grid",
    gridTemplateColumns: "1fr",
    marginTop: "1rem",
  })}
`;
