import React from "react";
import styled from "styled-components";
import Avatar from "../../Assets/Picture/avatar.png";
import { mobile, tablet } from "../../responsive";
export const Omoss = () => {
  return (
    <Container>
      <Desc>
        <h3> VI ÄR BSVBYGG</h3>
        <p>
          Lorem ipsum odor amet, consectetuer adipiscing elit. Platea mattis
          euismod id etiam nullam ad. Fames turpis orci; lobortis fermentum
          fames magnis arcu. Vivamus maecenas vitae tempor tincidunt primis sed
          dui maximus. Elementum himenaeos augue senectus tempus ante eget enim
          eget. Donec ullamcorper euismod odio a amet placerat justo. Sagittis
          convallis malesuada laoreet duis varius bibendum auctor.
        </p>
      </Desc>
      <AvatarPic>
        <img src={Avatar} alt="" width="50%"></img>
        <DescEmp>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Platea mattis
            euismod id etiam nullam ad. Fames turpis orci; lobortis fermentum
            fames magnis arcu. Vivamus maecenas vitae tempor tincidunt primis
            sed dui maximus. Elementum himenaeos augue senectus tempus ante eget
            enim eget. Donec ullamcorper euismod odio a amet placerat justo.
            Sagittis convallis malesuada laoreet duis varius bibendum auctor.
          </p>
        </DescEmp>
        <img src={Avatar} alt="" width="50%"></img>
        <DescEmp>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Platea mattis
            euismod id etiam nullam ad. Fames turpis orci; lobortis fermentum
            fames magnis arcu. Vivamus maecenas vitae tempor tincidunt primis
            sed dui maximus. Elementum himenaeos augue senectus tempus ante eget
            enim eget. Donec ullamcorper euismod odio a amet placerat justo.
            Sagittis convallis malesuada laoreet duis varius bibendum auctor.
          </p>
        </DescEmp>
      </AvatarPic>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({
    fontSize: "16px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "-0.5rem",
    marginBottom: "0.5rem",
    maxWidth: "100%",
  })}
`;
const Desc = styled.div`
  margin-top: 2rem;
  width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({
    fontSize: "14px",
    width: "400px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "0.5rem",
    lineHeight: "21px",
    maxWidth: "100%",
  })}
  ${tablet({
    width: "80%",
    paddingRight: "2rem",
    paddingLeft: "2rem",
  })}
`;

const DescEmp = styled.div``;
const AvatarPic = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  gap: 1rem;
  width: 50%;
  margin-bottom: 3rem;

  img {
    display: flex;
    width: 30%;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
`;
