import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ButtonStyle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isStartpage = location.pathname === "/";
  return (
    <>
      {isStartpage ? (
        <Contact2 href="#form">KONTAKT</Contact2>
      ) : (
        <Contact
          onClick={() => navigate(isStartpage ? "#form" : "/kontaktsida")}
        >
          KONTAKT
        </Contact>
      )}
    </>
  );
};

const Contact = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 550;
  font-family: Roboto;
  color: #fff;
  font-size: 0.85rem;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  margin: 1rem;
  border-radius: 50px;
  background-image: linear-gradient(180deg, #f78125, #dc6e33);
  box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%),
    0 1px 3px 0 rgb(93 100 148 / 20%);
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: 0 8px 22px 0 rgb(37 44 97 / 25%),
      0 4px 6px 0 rgb(93 100 148 / 20%);
  }
`;

const Contact2 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 550;
  font-family: Roboto;
  color: #fff;
  font-size: 0.85rem;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  margin: 1rem;
  border-radius: 50px;
  background-image: linear-gradient(180deg, #f78125, #dc6e33);
  box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%),
    0 1px 3px 0 rgb(93 100 148 / 20%);
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: 0 8px 22px 0 rgb(37 44 97 / 25%),
      0 4px 6px 0 rgb(93 100 148 / 20%);
  }
`;
export default ButtonStyle;
