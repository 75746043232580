import Navbar from "./components/Navbar";
import "./global/Global.css";
import "../src/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Tak } from "./components/pages/Tak";
import { Tjanster } from "./components/pages/Tjanster";
import { Priser } from "./components/pages/Priser";
import { Omdomen } from "./components/pages/Omdomen";
import { Omoss } from "./components/pages/Omoss";
import { Contact } from "./components/pages/Contact";
import { Startpage } from "./components/pages/Startpage";
import Footer from "./components/Footer";
import Kok from "./components/pages/Kok";
import Badrum from "./components/pages/Badrum";
import Altan from "./components/pages/Altan";
import Ovrigt from "./components/pages/Ovrigt";
import { ScrollToTop } from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <div>
        <Routes>
          <Route path="/" element={<Startpage />} />
          <Route path="/tak" element={<Tak />} />
          <Route path="/tjanster" element={<Tjanster />} />
          <Route path="/priser" element={<Priser />} />
          <Route path="/omdomen" element={<Omdomen />} />
          <Route path="/omoss" element={<Omoss />} />
          <Route path="/kontaktsida" element={<Contact />} />
          <Route path="/kok" element={<Kok />} />
          <Route path="/badrum" element={<Badrum />} />
          <Route path="/altan" element={<Altan />} />
          <Route path="/ovrigt" element={<Ovrigt />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
