import React from "react";
import styled from "styled-components";
import hero from "../Assets/Picture/HERO.png";
import { mobile } from "../responsive";

const Hero = () => {
  return (
    <Container>
      <img src={hero} alt="" width="100%" />
      <Company>
        <h3> INFORMATION OM TAK/BOLAGET</h3>
        <CompanyDesc>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Platea mattis
            euismod id etiam nullam ad. Fames turpis orci; lobortis fermentum
            fames magnis arcu. Vivamus maecenas vitae tempor tincidunt primis
            sed dui maximus. Elementum himenaeos augue senectus tempus ante eget
            enim eget. Donec ullamcorper euismod odio a amet placerat justo.
            Sagittis convallis malesuada laoreet duis varius bibendum auctor.
          </p>
        </CompanyDesc>
      </Company>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({ fontSize: "16px" })}
`;

const Company = styled.div`
  margin-top: 2rem;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({
    width: "400px",
    paddingRight: "2rem",
    paddingLeft: "2rem",
    marginTop: "0.5rem",
    maxWidth: "100%",
  })}
`;

const CompanyDesc = styled.p`
  ${mobile({ fontSize: "14px", lineHeight: "21px", marginTop: "-0.5rem" })}
`;

export default Hero;
