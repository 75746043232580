import React from "react";
import Prislista from "../../Assets/Picture/Prislista.png";
import styled from "styled-components";
import { mobile } from "../../responsive";

export const Priser = () => {
  return (
    <Container>
      <img src={Prislista} alt="" width="80%" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${mobile({
    height: "100%",
    marginBottom: "8rem",
    marginTop: "4rem",
  })}
`;
