import React from "react";
import Boxpush from "../Boxpush";
import Hero from "../Hero";
/* import { Omdomen } from "../Omdomen"; */
import { Formular } from "../Formular";

export const Startpage = () => {
  return (
    <div>
      <Hero />
      <Boxpush />
      <Formular />
      {/*      <Omdomen /> */}
    </div>
  );
};
