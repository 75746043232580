import React from "react";
import ruta1 from "../Assets/Picture/KOK.png";
import ruta2 from "../Assets/Picture/BADRUM.png";
import ruta3 from "../Assets/Picture/ALTAN.png";
import ruta4 from "../Assets/Picture/OVRIGT.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { mobile } from "../responsive";

const Boxpush = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <img
        src={ruta1}
        alt=""
        width="100%"
        onClick={() => navigate("/Kok")}
      ></img>
      <img
        src={ruta2}
        alt=""
        width="100%"
        onClick={() => navigate("/Badrum")}
      ></img>
      <img
        src={ruta3}
        alt=""
        width="100%"
        onClick={() => navigate("/Altan")}
      ></img>
      <img
        src={ruta4}
        alt=""
        width="100%"
        onClick={() => navigate("/Ovrigt")}
      ></img>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1rem;
  margin-top: 3rem;
  ${mobile({
    fontSize: "10px",
    marginTop: "0.5rem",
    gap: "0.5rem",
  })}
`;

export default Boxpush;
