import React, { useState } from "react";
import styled from "styled-components";
import logga from "../Assets/Picture/logga.png";
import { Link } from "react-router-dom";
import { mobile, desktop } from "../responsive";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ButtonStyle from "./ButtonStyle";

const Navbar = () => {
  const [extendNavbar, setExtendNavbar] = useState(false);
  let closed = true;

  return (
    <>
      <Header style={{ overflow: "hidden" }}>
        <NavbarInnerContainer extendNavbar={extendNavbar}>
          <HeaderTopics>
            <p>CERTIFIERADE HANTVERKARE</p>
            <p>NÖJD KUNDGARANTI</p>
            <p>SÄKER BETALNING</p>
            <p>SNABBA OFFERTER</p>
          </HeaderTopics>
          <Container>
            <Left>
              {" "}
              <Logo>
                <Link to="/">
                  <img src={logga} alt="" />
                </Link>
              </Logo>
            </Left>

            <Right>
              <li>
                <Link to="/tak">TAK</Link>
              </li>
              <li>
                <Link to="/tjanster">TJÄNSTER</Link>
              </li>
              <li>
                {" "}
                <Link to="/priser">PRISER</Link>
              </li>
              {/*  <li>
              <Link to="/omdomen">OMDÖMEN</Link>
            </li> */}
              <li>
                <Link to="/omoss">OM OSS</Link>
              </li>

              <ButtonStyle />
            </Right>
          </Container>
        </NavbarInnerContainer>
        <HamHeader>
          <OpenLinksButton
            onClick={() => {
              setExtendNavbar((curr) => {
                if (curr) {
                  setTimeout(() => {
                    closed = false;
                  }, 500);
                }
                return !curr;
              });
            }}
          >
            {extendNavbar ? (
              <CloseIcon fontSize="large" />
            ) : (
              <MenuIcon fontSize="large" />
            )}
          </OpenLinksButton>

          <LinkHam>
            <Link to="/">
              <img src={logga} alt="" />
            </Link>
          </LinkHam>
        </HamHeader>

        <NavbarExtendedContainer
          className={extendNavbar ? "" : !closed ? "" : "closed"}
        >
          <NavBarLinkExtended onClick={() => setExtendNavbar(false)} to="/">
            HEM
          </NavBarLinkExtended>
          <NavBarLinkExtended onClick={() => setExtendNavbar(false)} to="/tak">
            TAK
          </NavBarLinkExtended>
          <NavBarLinkExtended
            onClick={() => setExtendNavbar(false)}
            to="/tjanster"
          >
            TJÄNSTER
          </NavBarLinkExtended>
          <NavBarLinkExtended
            onClick={() => setExtendNavbar(false)}
            to="/priser"
          >
            PRISER
          </NavBarLinkExtended>
          <NavBarLinkExtended
            onClick={() => setExtendNavbar(false)}
            to="/omoss"
          >
            OM OSS
          </NavBarLinkExtended>
        </NavbarExtendedContainer>
        <NavbarBackground
          onClick={() => setExtendNavbar(false)}
          className={extendNavbar ? "open" : "closed"}
        />
      </Header>
    </>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderTopics = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  height: 30px;
  background-color: #a8a9ad;

  p {
    font-size: 14px;
  }
  ${mobile({ display: "none" })}
`;

const Container = styled.div`
  display: flex;
  background-color: solid #f5f5f5;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  ${mobile({ display: "none" })}
`;

const Left = styled.div`
  margin-left: 5rem;
`;
const Right = styled.ul`
  display: flex;
  gap: 2rem;
  list-style: none;
  align-items: center;

  li {
    padding: 0.25rem;
  }

  a {
    text-decoration: none;
    color: #333333;
  }
`;

const Logo = styled.div`
  cursor: pointer;
  img {
    width: 90px;
  }
  ${mobile({ display: "none" })}
`;

const LinkHam = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }
`;

const NavbarInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavbarBackground = styled.div`
  &.open {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 56px;
    left: 0;
  }

  &.closed {
    display: none;
  }
`;

const HamHeader = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  height: 3.5rem;
  ${desktop({ display: "none" })}
`;

const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px 5px #00000070;
  padding: 3.5rem 0 0 10px;
  transition: 0.5s ease-in-out;
  z-index: 2;

  &.open {
    right: 0;
  }

  &.closed {
    right: -310px;
  }

  > button {
    align-self: flex-end;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;
const NavBarLinkExtended = styled(Link)`
  color: black;
  font-size: 16px;
  text-decoration: none;
  margin: 10px;
`;

const OpenLinksButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  @media (min-width: 600px) {
    display: none;
  }
`;

export default Navbar;
