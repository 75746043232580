import React, { useRef } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { mobile } from "../responsive";

export const Formular = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_a3ztrrh",
      "template_8eck9sc",
      form.current,
      "Lzr9D9U7ArchcpR0H"
    );

    e.target.reset();
  };

  return (
    <Contactsection id="form">
      <ContactHeader>
        <h1>KONTAKA OSS</h1>
        <h2>Behöver du hjälp med något?</h2>
      </ContactHeader>
      <ContactContainer>
        <Form ref={form} onSubmit={sendEmail}>
          <InputCon type="text" name="name" placeholder="Ditt Namn" required />
          <InputCon type="email" name="email" placeholder="Din Mail" required />
          <TextArea
            name="message"
            rows="7"
            placeholder="Ditt Meddelande"
            required
          ></TextArea>
          <SendButton type="submit">SKICKA</SendButton>
        </Form>
      </ContactContainer>
    </Contactsection>
  );
};

const Contactsection = styled.div`
  width: 100%;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const ContactHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  ${mobile({
    fontSize: "10px",
    paddingRight: "2rem",
    paddingLeft: "2rem",
    marginTop: "0",
    lineHeight: "12px",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })}
`;

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;
const InputCon = styled.input`
  display: grid;
  grid-template-columns: 1fr;
  width: 60%;
  height: 50px;
  font-family: Roboto;
  font-size: 16px;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 3px 1px #7f7f7f30;

  ${mobile({
    fontSize: "12px",
    maxWidth: "60%",
  })}
`;
const TextArea = styled.textarea`
  width: 60%;
  height: 300px;
  font-family: Roboto;
  font-size: 16px;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 3px 1px #7f7f7f30;
  ${mobile({
    fontSize: "12px",
    maxWidth: "60%",
  })}
`;
const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 550;
  font-family: Roboto;
  color: #fff;
  font-size: 0.85rem;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  margin: 1rem;
  border-radius: 50px;
  background-image: linear-gradient(180deg, #f78125, #dc6e33);
  box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%),
    0 1px 3px 0 rgb(93 100 148 / 20%);
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: 0 8px 22px 0 rgb(37 44 97 / 25%),
      0 4px 6px 0 rgb(93 100 148 / 20%);
  }
`;
