import React from "react";
import styled from "styled-components";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logga from "../Assets/Picture/logga.png";
import { mobile } from "../responsive";

export default function Footer() {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterMain>
          <FooterColumn>
            <img src={logga} alt="" />
            <CompanyDesc>
              <p>Org.nr: 55565-3224 </p>
              <p>Adress: Signalgatan 1 </p>
              <p>442 40 Kungälv</p>
              <p>Tel: 072-949 45 85</p>
              <p>Mail:dsvn@gmail.com</p>
            </CompanyDesc>
          </FooterColumn>
          {/*  <FooterColumn>
            <FooterHeading>VÅRA TJÄNSTER</FooterHeading>

            <FooterLink onClick={() => navigate("/Tak")}>Tak</FooterLink>
            <FooterLink onClick={() => navigate("/ContactUs")}>
              Contact us
            </FooterLink>
          </FooterColumn> */}
        </FooterMain>
        <FooterBottom>
          <SocialWrapper>
            <FooterSocial
              href="https://www.instagram.com/a.s.tilt/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
            </FooterSocial>
            <FooterSocial
              href="https://www.facebook.com/TILT.SE"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
            </FooterSocial>
          </SocialWrapper>
          <Copyright>
            {" "}
            BSVBygg © {new Date().getFullYear()} - All rights reserved.
          </Copyright>
        </FooterBottom>
      </FooterWrapper>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  background-color: #141414;
  color: #ffffff;
  padding: 1rem 1rem;
`;
const FooterWrapper = styled.div``;
const FooterMain = styled.div``;

const CompanyDesc = styled.div`
  margin-left: 1rem;

  p {
    margin-bottom: 3px;
    margin-top: 0;
  }

  ${mobile({
    fontSize: "10px",
  })}
`;
const FooterColumn = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
/* const FooterHeading = styled.div`
  margin-bottom: 1rem;
`; */
/* const FooterLink = styled.div``; */
const FooterBottom = styled.div`
  margin-bottom: 0.5rem;
`;
const Copyright = styled.div`
  display: flex;
  justify-content: center;
  ${mobile({
    fontSize: "10px",
  })}
`;
const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`;
const FooterSocial = styled.div`
  font-size: 34px;
  margin-left: 0.4rem;
  color: white;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: red;
  }

  ${mobile({
    fontSize: "24px",
  })}
`;
